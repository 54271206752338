import * as React from "react"
import Layout from "../../Layout"


const GroupePage = () => (
  <Layout>
    <h1>à Supprimer ou à faire redirection</h1>
  </Layout>
)

export default GroupePage
